import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  pageDimensions,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import sanityImage from '../../../utils/sanityImage';
import Link from '../../atoms/Link/Link';
import Card from '../../atoms/Card/Card';
import AnimatedButton from '../../atoms/Buttons/AnimatedButton/AnimatedButton';
import Image from '../../atoms/Image/Image';
import Section from '../../molecules/Section/Section';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import ResponsiveContentWrapper from '../ResponsiveContentWrapper/ResponsiveContentWrapper';

const cardContainerCss = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
  margin-bottom: ${uc('50px')};
`;

const columnDesktopDownCss = css`
  width: calc(50% - ${uc('25px')});
  margin-bottom: ${uc('40px')};

  @media (${breakpoints.largeTablet}) {
    width: 100%;
    margin-bottom: ${uc('50px')};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const cardCss = css`
  width: 100%;

  > a,
  > div {
    display: flex;
    align-items: center;
    padding: ${uc('52px 45px 64px 45px')};
    text-decoration: none;

    @media (${breakpoints.mobile}) {
      padding: ${uc('35px')};
    }
  }

  img {
    align-self: center;
    width: ${uc('87px')};
    margin-right: ${uc('20px')};
  }

  ${Heading3} {
    margin-bottom: ${uc('5px')};
    color: ${colors.primary};
    font-size: ${fontSizes.twentyFive};
    line-height: ${lineHeights.title};
    letter-spacing: normal;

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.eighteen};
    }
  }

  ${Text} {
    max-width: ${uc('420px')};
    margin-bottom: ${uc('15px')};

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fourteen};
    }
  }
`;

const subheadingCss = css`
  color: ${colors.darkGray.three};
  font-weight: ${fontWeights.bold};
`;

const textBlockCss = css`
  max-width: ${uc('876px')};

  h2 {
    /* had to add !important to override textblock default
        styles - needs refactor */
    font-size: ${fontSizes.thirtyEight} !important;
  }

  p {
    /* had to add !important to override textblock default
        styles - needs refactor */
    font-weight: ${fontWeights.medium} !important;
    font-size: ${fontSizes.twenty} !important;
  }

  @media (${breakpoints.largeTablet}) {
    margin-bottom: ${uc('50px')};
  }
`;

/**
 * Get the color to use from base
 *
 * @param color
 * @return {*|boolean}
 */
const getColor = color => {
  let colorValue = false;
  switch (color) {
    case 'lightGray':
      colorValue = colors.lightGray.one;
      break;
    case 'gray':
      colorValue = colors.darkGray.two;
      break;
    default:
      colorValue = colors[color];
  }

  return colorValue;
};

const CtaCardsSection = ({
  backgroundImage,
  ctaCards,
  sectionStyles,
  textBlock,
}) => {
  const background = backgroundImage
    ? `url(${sanityImage(backgroundImage)
        .width(pageDimensions.rawLargeDesktopWidth)
        .fit('max')
        .auto('format')
        .url()});`
    : '';
  const sectionCss = css`
    position: relative;
    z-index: 1;
    text-align: center;
    background-image: ${background};
    background-size: cover;
  `;

  const renderedCards = ctaCards
    ? ctaCards.map(card => {
        // look for link from Link or AnimatedButton
        let cardLink = '#';

        if (card.link && card.link.link.length > 0) {
          cardLink = card.link;
        } else if (
          card.animatedButton &&
          card.animatedButton.link.link.length > 0
        ) {
          cardLink = card.animatedButton.link;
        }

        let headingColor = false;
        if (card.headingColor === undefined) {
          headingColor = getColor('gray');
        } else {
          headingColor = getColor(card.headingColor);
        }

        const textContainerCss = css`
          max-width: ${uc('420px')};
          text-align: left;

          ${Heading3} {
            color: ${headingColor};
          }
        `;

        return (
          <div css={columnDesktopDownCss} key={card._key}>
            <Card
              css={cardCss}
              backgroundColor={colors.white}
              boxShadow={shadows.large}
              hoverStyles={{ boxShadow: shadows.largeHovered }}
            >
              {cardLink && (
                <Link to={cardLink}>
                  <Image
                    src={sanityImage(card.image)
                      .auto('format')
                      .width(88)
                      .url()}
                    alt={card.alt}
                  />
                  <div css={textContainerCss}>
                    {card.heading && <Heading3>{card.heading}</Heading3>}
                    {card.subheading && (
                      <Text css={subheadingCss}>{card.subheading}</Text>
                    )}
                    {card.text && <Text>{card.text}</Text>}
                    {card.animatedButton && (
                      <AnimatedButton
                        color={card.animatedButton.color}
                        {...card.animatedButton}
                      >
                        {card.animatedButton.link.text}
                      </AnimatedButton>
                    )}
                  </div>
                </Link>
              )}
            </Card>
          </div>
        );
      })
    : null;

  return (
    <Section sectionStyles={sectionStyles} css={sectionCss}>
      <ResponsiveContentWrapper>
        {textBlock && (textBlock.headingText || textBlock.description) && (
          <TextBlock css={textBlockCss} {...textBlock} />
        )}
        <div css={cardContainerCss}>{renderedCards}</div>
      </ResponsiveContentWrapper>
    </Section>
  );
};

CtaCardsSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  ctaCards: PropTypes.arrayOf(PropTypes.shape([])),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  textBlock: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

CtaCardsSection.defaultProps = {
  backgroundImage: null,
  ctaCards: [],
  sectionStyles: {},
  textBlock: [],
};

export default CtaCardsSection;
